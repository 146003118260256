/* eslint-disable @typescript-eslint/typedef */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "nauth",
    pathMatch: "full"
  },
  {
    path: "nauth",
    loadChildren: () => import("./module/not-logged/not-logged.module").then(m => m.NotLoggedModule)
  },
  {
    path: "loggedin",
    loadChildren: () => import("./module/loggedin/loggedin.module").then(m => m.LoggedInModule)
  },
  {
    path: "**",
    redirectTo: "nauth",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
