import { Component } from "@angular/core";
import { PropertiesService } from "./shared/services/properties.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent {
  title = 'brigde-now-ui';
  ready: boolean = false;
  error: boolean = false;
  errorMsg: string = "";

  constructor (
    private propertiesService: PropertiesService,

  ) {
    this.propertiesService.readAllProperties().subscribe(() => {
        this.ready = true;
    });
  }
}
